import {get, isNullOrUndefined} from "@kubric/utils";
import {graphql} from "gatsby";
import React from "react";

import Layout from "../../components/Layout";
import StructuredData, {
  StructuredDataType,
} from "../../components/StructuredData";
import FormSection from "../../components/commons/FormSection";
import CarouselSection from "../../components/pages/makerslist/CarouselSection";
import HeroSection from "../../components/pages/makerslist/HeroSection";
import TilesSection from "../../components/pages/makerslist/TilesSection";
import fieldMap from "../../components/pages/makerslist/fieldmap";
import {parseJSON} from "../../utils/jsonparser";
import "./styles.scss";

const sections = {
  SanityMlHeroSection: HeroSection,
  SanityMlFormSection: FormSection,
  SanityMlTiles: TilesSection,
  SanityMlCarousel: CarouselSection,
};

const MakersList = ({data}) => {
  const seoData = data.allSanitySeoMeta.nodes[0];

  const parsedData = parseJSON(
    // TODO: Need to add alt for images in hero section, carousel entry card, carousels
    get(data, "sanityMlPage.mlSections", []),
    fieldMap
  );

  return (
    <Layout seoData={seoData}>
      <StructuredData
        type={StructuredDataType.BREADCRUMB}
        data={{
          name: "Unlock Commerce",
          items: [
            {name: "Unlock Commerce", item: "https://getmason.io/makerslist/"},
          ],
        }}
      />
      {parsedData.map(({type, data: {id, ...data}}) => {
        const Section = sections[type];
        if (!isNullOrUndefined(Section)) {
          return <Section data={data} key={id} />;
        }
        return undefined;
      })}
    </Layout>
  );
};

export const query = graphql`
  query MLPageQuery {
    allSanitySeoMeta(filter: {page: {eq: "makerslist"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
    sanityMlPage {
      mlSections {
        __typename
        ... on SanityMlCarousel {
          _id
          name
          carouselEntries {
            __typename
            ... on SanityMlCarouselHeroCard {
              _key
              cardHeading: heading {
                label
                bold
                _key
                color {
                  value
                }
              }
              bgImage {
                image {
                  asset {
                    _id
                  }
                }
                alt
              }
              fgImage {
                image {
                  asset {
                    _id
                  }
                }
                alt
              }
              borderColor {
                value
              }
            }
            ... on SanityMlCarouselEntryCard {
              _key
              heading
              description {
                _key
                children {
                  _key
                  marks
                  text
                }
                style
              }
              bgImage {
                image {
                  asset {
                    _id
                  }
                }
                alt
              }
              logo {
                image {
                  asset {
                    _id
                  }
                }
                alt
              }
              borderColor {
                value
              }
            }
          }
        }
        ... on SanityMlTiles {
          _id
          heading {
            label
            bold
            _key
            color {
              value
            }
          }
          tiles {
            _key
            heading
            logo {
              image {
                asset {
                  _id
                }
              }
              alt
            }
            description
            bgColor {
              value
            }
          }
        }
        ... on SanityMlFormSection {
          _id
          submitAction
          heading {
            label
            bold
            _key
            color {
              value
            }
          }
          controls {
            __typename
            ... on SanityMlButton {
              _key
              label
              bgColor {
                value
              }
              textColor {
                value
              }
            }
            ... on SanityMlFormField {
              _key
              label
              fieldName
              type
            }
            ... on SanityMlSelectBox {
              _key
              label
              fieldName
              options {
                _key
                label
                value
              }
            }
          }
        }
        ... on SanityMlHeroSection {
          _id
          styles {
            field
            styles {
              properties {
                property
                value
              }
            }
          }
          name
          isLeftImage
          animation
          hasCTA
          cta {
            cta
            textColor {
              name
              value
            }
            bgColor {
              name
              value
            }
            borderColor {
              name
              value
            }
            action
          }
          description {
            _key
            children {
              _key
              marks
              text
            }
            style
          }
          heading {
            label
            bold
            _key
            color {
              value
            }
          }
          heroImage {
            name
            image {
              asset {
                _id
              }
            }
            alt
          }
        }
      }
    }
  }
`;

export default MakersList;
