import {StyleExtractor} from "@kubric/utils";
import React from "react";

import Image from "../Image";
import RichText from "../RichText";
import * as styles from "./styles.module.scss";

const EntryCard = ({
  data: {bgImage, logo, borderColor, heading, description} = {},
}) => {
  const styler = new StyleExtractor(styles);
  return (
    <div
      className={styles.cardContainer}
      style={{
        borderColor,
      }}
    >
      {bgImage && (
        <Image
          data={bgImage}
          className={styles.bgImage}
          theme={{container: styles.bgContainer}}
        />
      )}
      <div className={styler.get(["overlay", "entryCard"])}>
        {logo && <Image data={logo} className={styles.logo} />}
        <div className={styles.entryHeading}>{heading}</div>
        <RichText
          data={description}
          theme={{container: styles.entryDescription}}
        />
      </div>
    </div>
  );
};

export default EntryCard;
