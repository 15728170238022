import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import Arrow from "../../../../assets/Arrow.svg";
import {useMediaQuery} from "../../../../hooks/useMediaQuery";
import "./slickoverrides.scss";

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  touchMove: true,
  slidesToScroll: 1,
  slidesToShow: 3,
  nextArrow: <Arrow />,
  prevArrow: <Arrow />,
};

const Carousel = ({children, settings: overrideSettings}) => {
  const isSmall = useMediaQuery("(max-width: 900px)");
  const finalSettings = isSmall
    ? {
        ...sliderSettings,
        slidesToShow: 1,
        dots: false,
      }
    : sliderSettings;
  return (
    <Slider {...finalSettings} {...overrideSettings}>
      {children}
    </Slider>
  );
};

export default Carousel;
