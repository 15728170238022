// The below 3 images together form the first version of the page seen
// Top banner image
import SVGAnimator from "../../../../lib/svganimator";

const topBannerImage =
  "https://media.kubric.io/api/assetlib/af672e04-2288-49e0-847c-9f3d7e3d8f36.webp";
// New arrivals image
const newArrivalsImage =
  "https://media.kubric.io/api/assetlib/d03932d5-dda7-4684-ad45-91dbb1658e80.webp";
// The first categories image shown before update
const oldCategoriesImage =
  "https://media.kubric.io/api/assetlib/072441ae-6828-488b-8f4c-75b9feef29c1.webp";

// The first chat bubble that comes up when the animation loads
const chat1Image =
  "https://media.kubric.io/api/assetlib/abcce274-48bb-44f9-9192-a34ecc1b5927.jpg";
// Responses to the first chat bubble
const chat1ResponseImage =
  "https://media.kubric.io/api/assetlib/b780ad66-0787-449a-8925-2662918fb51e.webp";

// Swap icon that comes to the right of the page indicating categories section swap to the top
const swapImage =
  "https://media.kubric.io/api/assetlib/97bcbf77-2f61-4496-8ec0-7ffc878cbf52.svg";
// New categories image that comes up in the page section after the swap
const newCategoriesImage =
  "https://media.kubric.io/api/assetlib/e6448254-aa5b-48ec-98bd-65ea304a5078.webp";

// Spring icon that comes up near the banner that leads to the new convo
const springImage =
  "https://media.kubric.io/api/assetlib/9f99a63f-cef7-466f-b525-811f6b059c98.png";
// Images for the new convo
const chat2Image =
  "https://media.kubric.io/api/assetlib/565f12bd-024b-4e60-a719-cf63f5508457.webp";
const chat2ResponseImage =
  "https://media.kubric.io/api/assetlib/41eb66bb-22cf-4535-89cb-eb2fab8ffa28.jpg";

class Animator {
  start(svgElement) {
    SVGAnimator.getInstance().then((Snap) => {
      this.s = new Snap(svgElement);
      this.animate();
    });
  }

  animate() {
    const {s} = this;
    // These 3 elements together form the initial version of the page seen when the animation starts
    // Top banner element that is always visible
    s.paper.image(topBannerImage, 271, 0, 352, 122);
    // New arrivals page section element
    const newArrivals = s.paper.image(newArrivalsImage, 271, 130, 352, 147);
    // First categories page section element
    const oldCategories = s.paper.image(oldCategoriesImage, 271, 290, 352, 198);

    // First chat bubble element
    const firstChat = s.paper.image(chat1Image, 0, 126, 217, 79.9).attr({
      opacity: 0,
    });
    // First chat response bubble element
    const firstChatResponses = s.paper
      .image(chat1ResponseImage, 0, 230, 217, 174.33)
      .attr({
        opacity: 0,
      });

    // Swap icon element that appears to the right of the page after the animations are visible
    const swapIcon = s.paper.image(swapImage, 581, 200, 183, 178).attr({
      opacity: 0,
    });

    // New categories section element that appears after the rearrangement of the page
    const newCategories = s.paper
      .image(newCategoriesImage, 271, 130, 352, 316)
      .attr({
        opacity: 0,
      });

    // Spring icon that leads to the new convo
    const spring = s.paper.image(springImage, 100, 0, 183, 178).attr({
      opacity: 0,
    });

    // New convo elements
    const secondChat = s.paper.image(chat2Image, 0, 195, 217, 60).attr({
      opacity: 0,
    });
    const secondChatResponses = s.paper
      .image(chat2ResponseImage, 0, 275, 217, 166)
      .attr({
        opacity: 0,
      });
    // When the animation starts, the page is visible i.e. topBanner, oldCategories and newArrivals
    // Show the first chat
    firstChat.animate({opacity: 1}, 1000, () => {
      // wait for some time
      setTimeout(() => {
        // Show the first chat responses
        firstChatResponses.animate({opacity: 1}, 1000, () => {
          // Wait for some time
          setTimeout(() => {
            swapIcon.animate({opacity: 1}, 1000, () => {
              newArrivals.animate({y: 450}, 1000);
              oldCategories.animate({opacity: 0}, 500);
              setTimeout(() => {
                newCategories.animate({opacity: 1}, 1200, () => {
                  swapIcon.attr({
                    opacity: 0,
                  });
                  // Hide the chats
                  firstChatResponses.attr({
                    opacity: 0,
                  });
                  firstChat.attr({
                    opacity: 0,
                  });
                  setTimeout(() => {
                    spring.animate(
                      {
                        opacity: 1,
                      },
                      1000,
                      () => {
                        secondChat.animate(
                          {
                            opacity: 1,
                          },
                          1000,
                          () => {
                            secondChatResponses.animate(
                              {
                                opacity: 1,
                              },
                              1000
                            );
                            setTimeout(
                              this.onAnimationCompleted.bind(this),
                              2000
                            );
                          }
                        );
                      }
                    );
                  }, 500);
                });
              }, 500);
            });
          }, 1500);
        });
      }, 1000);
    });
  }

  onAnimationCompleted() {
    this.s.clear();
    this.animate();
  }
}

export default new Animator();
