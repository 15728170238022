export default class SVGAnimator {
  static initialized = false;

  static instance = undefined;

  static getInstance() {
    return new Promise((resolve, reject) => {
      if (!SVGAnimator.initialized) {
        const script = document.createElement("script");
        script.src =
          "https://cdnjs.cloudflare.com/ajax/libs/snap.svg/0.5.1/snap.svg-min.js";
        script.onload = () => {
          SVGAnimator.initialized = true;
          SVGAnimator.instance = window.Snap;
          resolve(SVGAnimator.instance);
        };
        script.onerror = () => {
          reject();
        };
        document.querySelector("body").appendChild(script);
      } else {
        resolve(SVGAnimator.instance);
      }
    });
  }
}
