import {StyleExtractor} from "@kubric/utils";
import React from "react";

import Heading from "../../../commons/Heading";
import Image from "../Image";
import * as styles from "./styles.module.scss";

const HeroCard = ({data: {bgImage, fgImage, borderColor, heading} = {}}) => {
  const styler = new StyleExtractor(styles);
  return (
    <div
      className={styles.cardContainer}
      style={{
        borderColor,
      }}
    >
      {bgImage && (
        <Image
          data={bgImage}
          className={styler.get(["overlay", "bgImage"])}
          theme={{container: styles.bgContainer}}
        />
      )}
      <div className={styles.overlay}>
        <Heading data={heading} theme={{container: styles.heading}} h2 />
        {fgImage && <Image data={fgImage} className={styles.fgImage} />}
      </div>
    </div>
  );
};

export default HeroCard;
