import React, {useEffect, useState} from "react";

import animator from "./Animator";

const MakersListAnimation = ({style}) => {
  const [svgElement, setSVGRef] = useState();
  useEffect(() => {
    if (svgElement) {
      animator.start(svgElement);
    }
  }, [svgElement]);
  return <svg viewBox='0 0 800 600' ref={setSVGRef} style={style} />;
};

export default MakersListAnimation;
