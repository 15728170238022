import React from "react";

import {useMediaQuery} from "../../../../hooks/useMediaQuery";
import Heading from "../../../commons/Heading";
import Carousel from "../Carousel";
import Tile from "./Tile";
import * as styles from "./styles.module.scss";

const TilesSection = ({data: {heading, tiles = []} = {}}) => {
  const isSmall = useMediaQuery("(max-width: 900px)");
  const tileElements = tiles.map(({id, ...tile}) => (
    <Tile key={id} data={tile} />
  ));
  return isSmall ? (
    <Carousel settings={{slidesToShow: 1, lazyLoad: true}}>
      {tileElements}
    </Carousel>
  ) : (
    <div className={styles.tilesContainer}>
      {heading && <Heading data={heading} h2 />}
      <div className={styles.tiles}>{tileElements}</div>
    </div>
  );
};

export default TilesSection;
