// extracted by mini-css-extract-plugin
export var bgContainer = "styles-module--bgContainer--ThSzj";
export var bgImage = "styles-module--bgImage--3Lsd1";
export var cardContainer = "styles-module--cardContainer--4S7g1";
export var entryCard = "styles-module--entryCard--3yf69";
export var entryDescription = "styles-module--entryDescription--bMee7";
export var entryHeading = "styles-module--entryHeading--3HZe5";
export var fgImage = "styles-module--fgImage--1Lbm6";
export var heading = "styles-module--heading--1k2VB";
export var logo = "styles-module--logo--Geb1p";
export var overlay = "styles-module--overlay--2oM8K";