import {StyleExtractor, isValidString} from "@kubric/utils";
import React from "react";

import * as styles from "./styles.module.scss";

const RichText = ({data = [], style, theme = {}}) => {
  const styler = new StyleExtractor(styles, theme);
  return (
    <div className={styler.get("container")} style={style}>
      {data.map(({id, children = []}) => {
        const paraElements = children.map(({marks, text, id}) => {
          const [mark] = marks;
          if (!isValidString(mark)) {
            return <span key={id}>{text}</span>;
          }
          if (mark === "strong") {
            return <b key={id}>{text}</b>;
          }

          return <span key={id}>{text}</span>;
        });
        return (
          <div key={id} className={styler.get("para")}>
            {paraElements}
          </div>
        );
      })}
    </div>
  );
};

export default RichText;
