import {StyleExtractor} from "@kubric/utils";
import SanityImage from "gatsby-plugin-sanity-image";
import React from "react";

import * as styles from "./styles.module.scss";

let renderGatsbyImage = true;
if (typeof window !== "undefined") {
  renderGatsbyImage = !window.__testing;
}

const Image = ({data = {}, className, style, theme = {}}) => {
  const styler = new StyleExtractor(styles, theme);
  return (
    <span className={styler.get("container")} style={style}>
      {renderGatsbyImage ? (
        <SanityImage asset={data} className={className} alt='' />
      ) : (
        <img className={className} alt={data} src={data} style={style} />
      )}
    </span>
  );
};

export default Image;
