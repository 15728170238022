import {StyleExtractor, isUndefined} from "@kubric/utils";
import React from "react";

import CTA from "../../../commons/CTA";
import Heading from "../../../commons/Heading";
import MakersListAnimation from "../Animation";
import Image from "../Image";
import RichText from "../RichText";
import * as styles from "./styles.module.scss";

const animations = {
  mlAnimation: MakersListAnimation,
};

const HeroSection = ({data = {}}) => {
  const {
    cta,
    heading,
    description,
    hasCTA,
    heroImage,
    isLeftImage,
    styles: overrideStyles = {},
    animation,
    name,
  } = data;
  const styler = new StyleExtractor(styles);
  const Animation = animations[animation];
  const {
    section: sectionStyles,
    cta: ctaStyles,
    description: descriptionStyles,
    heading: headingStyles,
    heroImage: heroImageStyles,
  } = overrideStyles;
  const isTopHeroSection = name === "Top Hero Section";
  return (
    <div
      className={styler.get(["container", isLeftImage && "left"])}
      style={sectionStyles}
    >
      <div className={styles.content}>
        {heading && (
          <Heading
            data={heading}
            style={headingStyles}
            h2={!isTopHeroSection}
          />
        )}
        {description && (
          <RichText
            data={description}
            style={descriptionStyles}
            theme={{container: styles.description}}
          />
        )}
        {hasCTA && (
          <CTA data={cta} style={ctaStyles} theme={{cta: styles.cta}} />
        )}
      </div>
      {!isUndefined(Animation) ? (
        <Animation style={heroImageStyles} />
      ) : (
        heroImage && <Image data={heroImage} style={heroImageStyles} />
      )}
    </div>
  );
};

export default HeroSection;
