import {isNullOrUndefined} from "@kubric/utils";
import React from "react";

import Carousel from "../Carousel";
import EntryCard from "./EntryCard";
import HeroCard from "./HeroCard";

const cardsMap = {
  SanityMlCarouselEntryCard: EntryCard,
  SanityMlCarouselHeroCard: HeroCard,
};

const CarouselSection = ({data: {carouselEntries = []} = {}}) => {
  const cards = carouselEntries
    .map(({type, id, ...cardData}) => {
      const CardComponent = cardsMap[type];
      if (!isNullOrUndefined(CardComponent)) {
        return <CardComponent data={cardData} />;
      }
      return undefined;
    })
    .filter((card) => !!card);
  return <Carousel>{cards}</Carousel>;
};

export default CarouselSection;
