import React from "react";

import Image from "../Image";
import * as styles from "./styles.module.scss";

const Tile = ({
  data: {bgColor: backgroundColor, description, heading, logo} = {},
}) => (
  <div className={styles.container} style={{backgroundColor}}>
    <div className={styles.heading}>{heading}</div>
    <div className={styles.description}>{description}</div>
    {logo && <Image data={logo} className={styles.logo} />}
  </div>
);

export default Tile;
